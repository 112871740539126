import React, { useEffect } from 'react';
import styled from 'styled-components';
import { styles } from './base.styles';
import { AdBannerProps, AdPageType, getAdProps, marginMap } from './util';
import { useMediaQuery, useParams } from '@gf/cross-platform-lib/hooks';
import { useLaunchDarklyContext } from '@gf/cross-platform-lib/providers';

const GOOGLE_AD_SCRIPT_URL = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';

export const AdBanner = ({
  page,
  pageCustomTargetingParameters,
  sectionCustomTargetingParameters,
  slotCustomTargetingParameters
}: AdBannerProps) => {
  const { isNativeApp, isMobileWeb, isTablet } = useMediaQuery();
  const params: any = useParams();
  if (params && params['adtest']) {
    pageCustomTargetingParameters = { ...pageCustomTargetingParameters, adtest: params['adtest'] };
  }
  const launchDarklyContext = useLaunchDarklyContext();
  const { variation: adsEnabledMapping } = launchDarklyContext?.features['ads-enabled-mapping'] as unknown as {
    variation: Record<AdPageType, boolean>;
  };
  const pageEnabled = adsEnabledMapping[page] ?? false;

  const { unitId, sizes, slotId, customTargeting } = getAdProps(
    page,
    isNativeApp,
    isMobileWeb,
    isTablet,
    pageCustomTargetingParameters,
    sectionCustomTargetingParameters,
    slotCustomTargetingParameters
  );

  useEffect(() => {
    if (!pageEnabled) {
      return;
    }

    googletag.cmd.push(() => {
      const slot = googletag
        .defineSlot(unitId, sizes as [number, number] | [number, number][], slotId)
        ?.setCollapseEmptyDiv(true, true);
      if (slot) {
        slot.addService(googletag.pubads());
        customTargeting &&
          Array.from(Object.entries(customTargeting)).forEach(([key, value]) => {
            slot.setTargeting(key, [value as string]);
          });
        googletag.display(slotId as string);
        googletag.pubads().refresh([slot]);
      }
    });

    return () => {
      googletag.cmd.push(() => {
        const slot = googletag
          .pubads()
          .getSlots()
          .find(slot => slot.getSlotElementId() === slotId);
        if (slot) googletag.destroySlots([slot]);
      });
    };
  }, [sizes, slotId, unitId, page, pageEnabled]);

  if (!pageEnabled) {
    return null;
  }

  if (typeof window !== 'undefined' && !window.googletag) {
    console.log(pageEnabled, 'initializing googletag');
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(() => {
      googletag.pubads().disableInitialLoad();
      googletag.enableServices();
    });
  }

  if (window.document && !window.document.querySelector(`script[src="${GOOGLE_AD_SCRIPT_URL}"]`)) {
    const script = window.document.createElement('script');
    script.src = GOOGLE_AD_SCRIPT_URL;
    script.async = true;
    window.document.head.appendChild(script);
  }

  return (
    <AdWrapper data-testid={`test-ad-banner-${unitId}`}>
      <div
        id={slotId}
        style={{
          marginTop: marginMap(isMobileWeb, slotCustomTargetingParameters?.pos)[page]?.top,
          marginBottom: marginMap(isMobileWeb, slotCustomTargetingParameters?.pos)[page]?.bottom
        }}
      ></div>
    </AdWrapper>
  );
};

const AdWrapper = styled.div`
  ${styles.WebContent}
`;

export default AdBanner;
